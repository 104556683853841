import { Links, Meta, Outlet, Scripts, ScrollRestoration } from '@remix-run/react';

import '@/styles/date-time-picker.css';
import '@/styles/globals.css';
import '@/styles/react-big-calendar.css';
import '@/styles/richtext.css';
import '@/styles/utils.css';

import { RepairDynamicImport } from '@/common/components/RepairDynamicImport';
import { Box, Center, ChakraProvider, Spinner } from '@chakra-ui/react';
import { withEmotionCache } from '@emotion/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useContext, useEffect } from 'react';
import React from 'react';
import PwaPullToRefresh from './common/components/PwaPullToRefresh';
import { ClientStyleContext, ServerStyleContext } from './context';
import { ConfirmModalProvider } from './context/ConfirmModalContext';
import { LoadingProvider } from './context/LoadingContext';
import theme from './plugin/chakra';
import Datadog from './plugin/datadog';

interface DocumentProps {
  children: React.ReactNode;
}

export const Layout = withEmotionCache(({ children }: DocumentProps, emotionCache) => {
  const serverStyleData = useContext(ServerStyleContext);
  const clientStyleData = useContext(ClientStyleContext);

  // Only executed on client
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    // re-link sheet container
    emotionCache.sheet.container = document.head;
    // re-inject tags
    const tags = emotionCache.sheet.tags;
    emotionCache.sheet.flush();
    tags.forEach((tag) => {
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      (emotionCache.sheet as any)._insertTag(tag);
    });
    // reset cache to reapply global styles
    clientStyleData?.reset();
  }, []);

  return (
    <html translate='no' className='notranslate'>
      <head>
        <Meta />
        <Links />
        <link rel='preconnect' href='https://identitytoolkit.googleapis.com' />
        <link rel='preconnect' href='https://firebase.googleapis.com' />
        <link rel='preconnect' href='https://securetoken.googleapis.com' />
        <link rel='preconnect' href={import.meta.env.VITE_BACKEND_URL} />

        <title>M2X</title>
        <meta name='description' content='M2X Software' />
        <meta
          name='viewport'
          content='width=device-width,initial-scale=1,maximum-scale=1,interactive-widget=resizes-content'
        />
        <meta name='robots' content='noindex' />
        <link rel='icon' href='/favicon.ico' />
        <link rel='icon' type='image/png' sizes='32x32' href='/favicon-32x32.png' />
        <link rel='icon' type='image/png' sizes='16x16' href='/favicon-16x16.png' />
        <link rel='apple-touch-icon' href='/home-icon_20231101.png' />
        <link rel='manifest' href='/manifest.json' />
        <meta name='apple-mobile-web-app-capable' content='yes' />
        <meta name='apple-mobile-web-app-status-bar' content='#69C7B8' />
        <link
          rel='apple-touch-startup-image'
          media='screen and (device-width: 430px) and (device-height: 932px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)'
          href='/splash_screens/iPhone_15_Pro_Max__iPhone_15_Plus__iPhone_14_Pro_Max_landscape.png'
        />
        <link
          rel='apple-touch-startup-image'
          media='screen and (device-width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)'
          href='/splash_screens/iPhone_15_Pro__iPhone_15__iPhone_14_Pro_landscape.png'
        />
        <link
          rel='apple-touch-startup-image'
          media='screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)'
          href='/splash_screens/iPhone_14_Plus__iPhone_13_Pro_Max__iPhone_12_Pro_Max_landscape.png'
        />
        <link
          rel='apple-touch-startup-image'
          media='screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)'
          href='/splash_screens/iPhone_14__iPhone_13_Pro__iPhone_13__iPhone_12_Pro__iPhone_12_landscape.png'
        />
        <link
          rel='apple-touch-startup-image'
          media='screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)'
          href='/splash_screens/iPhone_13_mini__iPhone_12_mini__iPhone_11_Pro__iPhone_XS__iPhone_X_landscape.png'
        />
        <link
          rel='apple-touch-startup-image'
          media='screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)'
          href='/splash_screens/iPhone_11_Pro_Max__iPhone_XS_Max_landscape.png'
        />
        <link
          rel='apple-touch-startup-image'
          media='screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)'
          href='/splash_screens/iPhone_11__iPhone_XR_landscape.png'
        />
        <link
          rel='apple-touch-startup-image'
          media='screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)'
          href='/splash_screens/iPhone_8_Plus__iPhone_7_Plus__iPhone_6s_Plus__iPhone_6_Plus_landscape.png'
        />
        <link
          rel='apple-touch-startup-image'
          media='screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)'
          href='/splash_screens/iPhone_8__iPhone_7__iPhone_6s__iPhone_6__4.7__iPhone_SE_landscape.png'
        />
        <link
          rel='apple-touch-startup-image'
          media='screen and (device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)'
          href='/splash_screens/4__iPhone_SE__iPod_touch_5th_generation_and_later_landscape.png'
        />
        <link
          rel='apple-touch-startup-image'
          media='screen and (device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)'
          href='/splash_screens/12.9__iPad_Pro_landscape.png'
        />
        <link
          rel='apple-touch-startup-image'
          media='screen and (device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)'
          href='/splash_screens/11__iPad_Pro__10.5__iPad_Pro_landscape.png'
        />
        <link
          rel='apple-touch-startup-image'
          media='screen and (device-width: 820px) and (device-height: 1180px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)'
          href='/splash_screens/10.9__iPad_Air_landscape.png'
        />
        <link
          rel='apple-touch-startup-image'
          media='screen and (device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)'
          href='/splash_screens/10.5__iPad_Air_landscape.png'
        />
        <link
          rel='apple-touch-startup-image'
          media='screen and (device-width: 810px) and (device-height: 1080px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)'
          href='/splash_screens/10.2__iPad_landscape.png'
        />
        <link
          rel='apple-touch-startup-image'
          media='screen and (device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)'
          href='/splash_screens/9.7__iPad_Pro__7.9__iPad_mini__9.7__iPad_Air__9.7__iPad_landscape.png'
        />
        <link
          rel='apple-touch-startup-image'
          media='screen and (device-width: 744px) and (device-height: 1133px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)'
          href='/splash_screens/8.3__iPad_Mini_landscape.png'
        />
        <link
          rel='apple-touch-startup-image'
          media='screen and (device-width: 430px) and (device-height: 932px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)'
          href='/splash_screens/iPhone_15_Pro_Max__iPhone_15_Plus__iPhone_14_Pro_Max_portrait.png'
        />
        <link
          rel='apple-touch-startup-image'
          media='screen and (device-width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)'
          href='/splash_screens/iPhone_15_Pro__iPhone_15__iPhone_14_Pro_portrait.png'
        />
        <link
          rel='apple-touch-startup-image'
          media='screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)'
          href='/splash_screens/iPhone_14_Plus__iPhone_13_Pro_Max__iPhone_12_Pro_Max_portrait.png'
        />
        <link
          rel='apple-touch-startup-image'
          media='screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)'
          href='/splash_screens/iPhone_14__iPhone_13_Pro__iPhone_13__iPhone_12_Pro__iPhone_12_portrait.png'
        />
        <link
          rel='apple-touch-startup-image'
          media='screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)'
          href='/splash_screens/iPhone_13_mini__iPhone_12_mini__iPhone_11_Pro__iPhone_XS__iPhone_X_portrait.png'
        />
        <link
          rel='apple-touch-startup-image'
          media='screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)'
          href='/splash_screens/iPhone_11_Pro_Max__iPhone_XS_Max_portrait.png'
        />
        <link
          rel='apple-touch-startup-image'
          media='screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'
          href='/splash_screens/iPhone_11__iPhone_XR_portrait.png'
        />
        <link
          rel='apple-touch-startup-image'
          media='screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)'
          href='/splash_screens/iPhone_8_Plus__iPhone_7_Plus__iPhone_6s_Plus__iPhone_6_Plus_portrait.png'
        />
        <link
          rel='apple-touch-startup-image'
          media='screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'
          href='/splash_screens/iPhone_8__iPhone_7__iPhone_6s__iPhone_6__4.7__iPhone_SE_portrait.png'
        />
        <link
          rel='apple-touch-startup-image'
          media='screen and (device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'
          href='/splash_screens/4__iPhone_SE__iPod_touch_5th_generation_and_later_portrait.png'
        />
        <link
          rel='apple-touch-startup-image'
          media='screen and (device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'
          href='/splash_screens/12.9__iPad_Pro_portrait.png'
        />
        <link
          rel='apple-touch-startup-image'
          media='screen and (device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'
          href='/splash_screens/11__iPad_Pro__10.5__iPad_Pro_portrait.png'
        />
        <link
          rel='apple-touch-startup-image'
          media='screen and (device-width: 820px) and (device-height: 1180px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'
          href='/splash_screens/10.9__iPad_Air_portrait.png'
        />
        <link
          rel='apple-touch-startup-image'
          media='screen and (device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'
          href='/splash_screens/10.5__iPad_Air_portrait.png'
        />
        <link
          rel='apple-touch-startup-image'
          media='screen and (device-width: 810px) and (device-height: 1080px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'
          href='/splash_screens/10.2__iPad_portrait.png'
        />
        <link
          rel='apple-touch-startup-image'
          media='screen and (device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'
          href='/splash_screens/9.7__iPad_Pro__7.9__iPad_mini__9.7__iPad_Air__9.7__iPad_portrait.png'
        />
        <link
          rel='apple-touch-startup-image'
          media='screen and (device-width: 744px) and (device-height: 1133px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'
          href='/splash_screens/8.3__iPad_Mini_portrait.png'
        />
        {/* channel-talk-sdkにページ内にscriptタグが一つもないとエラーになる不具合があるのでその対処 */}
        {/* https://github.com/channel-io/channel-web-sdk-loader/blob/aca4b067c1be51f81db3cbc83921d907a907c833/src/index.ts#L175 */}
        <script></script>
        {serverStyleData?.map(({ key, ids, css }) => (
          <style
            key={key}
            data-emotion={`${key} ${ids.join(' ')}`}
            dangerouslySetInnerHTML={{ __html: css }}
          />
        ))}
      </head>
      <body>
        <ChakraProvider theme={theme}>{children}</ChakraProvider>
        <Scripts />
        <ScrollRestoration
          getKey={(location) => {
            // TODO workorderのルーティング変更で不要になるので削除
            return location.pathname === '/' ? location.hash : location.key;
          }}
        />
        <Datadog />
      </body>
    </html>
  );
});

const queryClient = new QueryClient();

export default function App() {
  return (
    <>
      <PwaPullToRefresh />
      <RepairDynamicImport />
      <Box id='page-refresher'>
        <QueryClientProvider client={queryClient}>
          <ConfirmModalProvider>
            <LoadingProvider>
              <Outlet />
            </LoadingProvider>
          </ConfirmModalProvider>
        </QueryClientProvider>
      </Box>
    </>
  );
}

export function HydrateFallback() {
  return (
    <>
      <Center w='full' h='50vh'>
        <Spinner color='primary.500' />
      </Center>
    </>
  );
}

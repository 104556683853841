import { datadogLogs } from '@datadog/browser-logs';
import { useEffect } from 'react';

export const RepairDynamicImport = () => {
  useEffect(() => {
    const handler = () => {
      datadogLogs.logger.warn('vite:preloadError occurred, reloading the page');
      window.location.reload();
    };

    window.addEventListener('vite:preloadError', handler);

    return () => {
      window.removeEventListener('vite:preloadError', handler);
    };
  }, []);

  return null;
};
